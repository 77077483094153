<template>
  <div class="mb-4 pr-2">
    <trac-loading v-if="isLoading" />
    <main v-if="paymentMethods">
      <div class="mb-8">
        <ul class="">
          <li><a class="text-sm" href="#">Settings</a></li>
          <li class="font-bold mt-4 text-lg">Payment Method</li>
        </ul>
      </div>
      <div
        class="px-8 py-8 bg-white h-full rounded-lg overflow-hidden big-shadow"
      >
        <div class="flex justify-between w-full">
          <div>
            <trac-custom-header>
              <template slot="leading">Own</template>
              <template slot="trailing"> Payment</template>
            </trac-custom-header>
          </div>
          <div class="flex items-start">
            <div
              @click="openModal = !openModal"
              class="cursor-pointer items-center flex"
            >
              <span class="text-xs text-primaryBlue"> Learn More </span>
              <svg
                width="7"
                class="ml-2"
                height="13"
                viewBox="0 0 7 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 11.5L6.4 6.1L1 0.700001"
                  stroke="#003283"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="flex flex-row   flex-wrap  gap-10">
          <div class="flex items-center">
            <div class="flex items-center">
              <svg
                width="35"
                height="21"
                viewBox="0 0 35 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.38034 0.913452H33.2218C33.4965 0.913452 33.7193 1.13599 33.7193 1.41098V19.3218C33.7193 19.5968 33.4965 19.8193 33.2218 19.8193H1.38034C1.1056 19.8193 0.882812 19.5968 0.882812 19.3218V1.41098C0.882812 1.13599 1.1056 0.913452 1.38034 0.913452ZM1.87803 18.8243H32.7245V1.90855H1.87803V18.8243Z"
                  fill="#253B95"
                />
                <path
                  d="M32.7245 18.8243V19.182H33.0821V18.8243H32.7245ZM1.87803 18.8243H1.52037V19.182H1.87803V18.8243ZM32.7245 1.90855H33.0821V1.55089H32.7245V1.90855ZM1.87803 1.90855V1.55089H1.52037V1.90855H1.87803ZM33.2218 0.555789H1.38034V1.27111H33.2218V0.555789ZM34.077 1.41098C34.077 0.938344 33.694 0.555789 33.2218 0.555789V1.27111C33.2991 1.27111 33.3617 1.33364 33.3617 1.41098H34.077ZM34.077 19.3218V1.41098H33.3617V19.3218H34.077ZM33.2218 20.177C33.694 20.177 34.077 19.7944 34.077 19.3218H33.3617C33.3617 19.3991 33.2991 19.4617 33.2218 19.4617V20.177ZM1.38034 20.177H33.2218V19.4617H1.38034V20.177ZM0.52515 19.3218C0.52515 19.7944 0.908186 20.177 1.38034 20.177V19.4617C1.30302 19.4617 1.24048 19.3991 1.24048 19.3218H0.52515ZM0.52515 1.41098V19.3218H1.24048V1.41098H0.52515ZM1.38034 0.555789C0.908188 0.555789 0.52515 0.938346 0.52515 1.41098H1.24048C1.24048 1.33364 1.30302 1.27111 1.38034 1.27111V0.555789ZM32.7245 18.4667H1.87803V19.182H32.7245V18.4667ZM32.3668 1.90855V18.8243H33.0821V1.90855H32.3668ZM1.87803 2.26621H32.7245V1.55089H1.87803V2.26621ZM2.2357 18.8243V1.90855H1.52037V18.8243H2.2357Z"
                  fill="#0143AA"
                />
                <path
                  d="M4.86276 16.3367H7.35037C7.62511 16.3367 7.8479 16.1142 7.8479 15.8392C7.8479 15.5642 7.62511 15.3417 7.35037 15.3417H4.86276C4.58803 15.3417 4.36523 15.5642 4.36523 15.8392C4.36523 16.1142 4.58803 16.3367 4.86276 16.3367Z"
                  fill="#253B95"
                />
                <path
                  d="M27.2514 5.39116H29.739C30.0138 5.39116 30.2366 5.16862 30.2366 4.89364C30.2366 4.61866 30.0138 4.39612 29.739 4.39612H27.2514C26.9767 4.39612 26.7539 4.61866 26.7539 4.89364C26.7539 5.16862 26.9767 5.39116 27.2514 5.39116Z"
                  fill="#253B95"
                />
                <path
                  d="M14.543 14.009V7.26377C14.543 6.72905 15.2583 6.55034 15.5098 7.02222L18.7761 13.1508C19.0275 13.6227 19.7428 13.4456 19.7428 12.9109C19.7428 10.9578 19.7428 7.99959 19.7428 5.79955"
                  stroke="#253B95"
                  stroke-width="1.00146"
                />
                <rect
                  x="12.3281"
                  y="11.0688"
                  width="10.0146"
                  height="1.00146"
                  fill="#253B95"
                />
                <rect
                  x="12.3281"
                  y="8.75586"
                  width="10.0146"
                  height="1.00146"
                  fill="#253B95"
                />
              </svg>

              <span class="text-sm ml-2 text-accentDark"> Cash</span>
            </div>
            <trac-toggle
              @newval="togglePaymentMethod($event, 'cash')"
              :active="paymentMethods.includes('cash')"
              class="text-sm font-semibold text-primaryBlue ml-8"
            ></trac-toggle>
          </div>
          <div class="flex items-center ">
            <div class="flex items-center">
              <img
                class="w-6 h-6"
                src="@/assets/svg/paymentMethod/pos.svg"
                alt=""
              />

              <span class="text-sm ml-2 text-accentDark"> Pos</span>
            </div>
            <trac-toggle
              @newval="togglePaymentMethod($event, 'pos')"
              :active="paymentMethods.includes('pos')"
              class="text-sm font-semibold text-primaryBlue ml-8"
            ></trac-toggle>
          </div>
          <div class="flex items-center ">
            <div class="flex items-center">
              <img
                class="w-6 h-6"
                src="@/assets/svg/paymentMethod/bank.svg"
                alt=""
              />

              <span class="text-sm ml-2 text-accentDark">
                Personal Bank Account</span
              >
            </div>
            <trac-toggle
              @newval="togglePaymentMethod($event, 'own_bank_account')"
              :active="paymentMethods.includes('own_bank_account')"
              class="text-sm font-semibold text-primaryBlue ml-8"
            ></trac-toggle>
          </div>
          <div class="flex items-center ">
            <div
              v-for="payment in customPayments"
              :key="payment._id"
              class="flex items-center mr-10"
            >
              <img
                class="w-6 h-6"
                src="@/assets/svg/paymentMethod/bank.svg"
                alt=""
              />

              <span class="text-sm ml-2 text-accentDark capitalize ">
                {{ payment.name }}</span
              >
              <div class="pl-10">
                <svg
                  @click="removeCustomPayment(payment._id)"
                  class="w-5 h-5  fill-current text-primaryBlue cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M256 0C114.508 0 0 114.497 0 256c0 141.493 114.497 256 256 256 141.492 0 256-114.497 256-256C512 114.507 397.503 0 256 0zm0 472c-119.384 0-216-96.607-216-216 0-119.385 96.607-216 216-216 119.384 0 216 96.607 216 216 0 119.385-96.607 216-216 216z"
                  />
                  <path
                    d="M343.586 315.302L284.284 256l59.302-59.302c7.81-7.81 7.811-20.473.001-28.284-7.812-7.811-20.475-7.81-28.284 0L256 227.716l-59.303-59.302c-7.809-7.811-20.474-7.811-28.284 0-7.81 7.811-7.81 20.474.001 28.284L227.716 256l-59.302 59.302c-7.811 7.811-7.812 20.474-.001 28.284 7.813 7.812 20.476 7.809 28.284 0L256 284.284l59.303 59.302c7.808 7.81 20.473 7.811 28.284 0s7.81-20.474-.001-28.284z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <trac-button
            @click.native="newPaymentModal = true"
            v-if="fixedAccount"
            class=""
            variant="outline"
          >
            <span class="px-3">Add Payment Method</span>
          </trac-button>
        </div>
        <trac-custom-header class="mt-16">
          <template slot="leading">Traction</template>
          <template slot="trailing"> Pay</template>
        </trac-custom-header>
        <div class="grid md:flex flex-row gap-4 md:gap-16 mb-4">
          <div class="flex items-center">
            <div class="flex items-center">
              <img
                class="w-6 h-6"
                src="@/assets/svg/paymentMethod/dynamic.svg"
                alt=""
              />

              <span class="text-sm ml-2 text-accentDark">
                Bank Transfer (Dynamic)</span
              >
            </div>
            <trac-toggle
              @newval="togglePaymentMethod($event, 'bank_transfer')"
              :active="paymentMethods.includes('bank_transfer')"
              class="text-sm font-semibold text-primaryBlue ml-8"
            ></trac-toggle>
          </div>
          <!-- <trac-toggle
            @newval="togglePaymentMethod($event, 'bank_transfer')"
            :active="paymentMethods.includes('bank_transfer')"
            class="text-sm font-semibold text-primaryBlue"
            >Bank Transfer</trac-toggle
          > -->
          <div class="flex items-center">
            <div class="flex items-center">
              <svg
                class="w-6 h-6"
                viewBox="0 0 25 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="1"
                  y="1"
                  width="23.2283"
                  height="29.0353"
                  rx="2.90353"
                  stroke="#253B95"
                  stroke-width="1.45177"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.6151 24.2284H12.6296"
                  stroke="#253B95"
                  stroke-width="1.45177"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.3926 14.8768V9.0098C10.3926 8.54471 11.0147 8.38926 11.2335 8.79971L14.0745 14.1303C14.2932 14.5407 14.9154 14.3868 14.9154 13.9217C14.9154 12.2228 14.9154 9.64981 14.9154 7.73624"
                  stroke="#253B95"
                  stroke-width="0.87106"
                />
                <rect
                  x="8.4668"
                  y="12.3182"
                  width="8.7106"
                  height="0.87106"
                  fill="#253B95"
                />
                <rect
                  x="8.4668"
                  y="10.3107"
                  width="8.7106"
                  height="0.87106"
                  fill="#253B95"
                />
              </svg>

              <span class="text-sm ml-2 text-accentDark"> Online Payment</span>
            </div>
            <trac-toggle
              @newval="togglePaymentMethod($event, 'online_payment')"
              :active="paymentMethods.includes('online_payment')"
              class="text-sm font-semibold text-primaryBlue ml-8"
            ></trac-toggle>
          </div>
          <div class="flex items-center">
            <div class="flex items-center">
              <svg
                class="w-6 h-6"
                viewBox="0 0 25 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="1"
                  y="1"
                  width="23.2283"
                  height="29.0353"
                  rx="2.90353"
                  stroke="#253B95"
                  stroke-width="1.45177"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.6151 24.2284H12.6296"
                  stroke="#253B95"
                  stroke-width="1.45177"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.3926 14.8768V9.0098C10.3926 8.54471 11.0147 8.38926 11.2335 8.79971L14.0745 14.1303C14.2932 14.5407 14.9154 14.3868 14.9154 13.9217C14.9154 12.2228 14.9154 9.64981 14.9154 7.73624"
                  stroke="#253B95"
                  stroke-width="0.87106"
                />
                <rect
                  x="8.4668"
                  y="12.3182"
                  width="8.7106"
                  height="0.87106"
                  fill="#253B95"
                />
                <rect
                  x="8.4668"
                  y="10.3107"
                  width="8.7106"
                  height="0.87106"
                  fill="#253B95"
                />
              </svg>

              <span class="text-sm ml-2 text-accentDark">
                USSD (Coming soon)</span
              >
            </div>
            <trac-toggle
              disabled
              @newval="togglePaymentMethod($event, 'ussd')"
              :active="paymentMethods.includes('')"
              class="text-sm font-semibold text-primaryBlue ml-8"
            ></trac-toggle>
          </div>

          <!-- <trac-toggle
            disabled
            @newval="togglePaymentMethod($event, '')"
            :active="paymentMethods.includes('')"
            class="text-sm font-semibold text-primaryBlue"
            >QR Code(Coming soon)</trac-toggle
          > -->
        </div>
        <div class="flex items-center mt-4 md:mt-12">
          <div class="flex items-center">
            <img
              class="w-6 h-6"
              src="@/assets/svg/paymentMethod/dynamic.svg"
              alt=""
            />

            <span class="text-sm ml-2 text-accentDark">
              Bank Transfer (Fixed)</span
            >
          </div>
          <trac-toggle
            @newval="togglePaymentMethod($event, 'fixed_account')"
            :active="paymentMethods.includes('fixed_account')"
            class="text-sm font-semibold text-primaryBlue ml-8"
          ></trac-toggle>
        </div>
        <!-- <trac-button
          @click.native="goToFixedAccount"
          v-if="fixedAccount"
          class="mt-4"
          variant="outline"
        >
          <span class="px-3">Manage Fixed Account</span>
        </trac-button> -->
      </div>
      <div class="flex flex-row mt-5 justify-end">
        <trac-button class="uppercase" @button-clicked="updatePaymentOptions"
          >Update</trac-button
        >
        <trac-button
          v-if="!$store.state.miscellaneous.selectPaymentReference"
          class="uppercase ml-5"
          @button-clicked="confirm"
          >Leave Unchanged</trac-button
        >
      </div>
    </main>
    <trac-modal
      size="max-w-xl"
      v-if="$store.state.miscellaneous.selectPaymentModal || openModal"
      @close="closeModal"
    >
      <h1 class="text-center text-primaryBlue text-sm pt-12">
        Here’s what you need to know about payment.
      </h1>
      <div class="flex w-full flex-col justify-center items-center mt-12">
        <div
          class="flex flex-col items-center justify-center bg-accentLight w-40 h-40 rounded-full"
        >
          <div>
            <img
              class="w-10 h-10"
              :src="getImg(accountsInfo[current].icon)"
              alt=""
            />
          </div>
          <div class="text-xs pt-5 text-primaryBlue px-10 text-center">
            {{ accountsInfo[current].name }}
          </div>
        </div>
        <div class="text-xs text-accentDark mt-12 leading-5 text-center px-16">
          {{ accountsInfo[current].text }}
        </div>
        <div class="mt-10 flex w-40 justify-between">
          <div>
            <svg
              @click="back"
              class="cursor-pointer"
              s
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="13.5"
                cy="13.5"
                r="13.5"
                transform="rotate(180 13.5 13.5)"
                fill="#0143AA"
                fill-opacity="0.0467931"
              />
              <circle
                cx="13.5"
                cy="13.5"
                r="13.2404"
                transform="rotate(180 13.5 13.5)"
                stroke="#253B95"
                stroke-opacity="0.33"
                stroke-width="0.519231"
              />
              <path
                d="M15.9229 8.30772L10.3844 13.8462L15.9229 19.3846"
                stroke="#013E9E"
                stroke-width="0.923077"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div>
            <svg
              @click="front"
              class="cursor-pointer"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="13.5"
                cy="13.5"
                r="13.5"
                fill="#0143AA"
                fill-opacity="0.0467931"
              />
              <circle
                cx="13.5"
                cy="13.5"
                r="13.2404"
                stroke="#253B95"
                stroke-opacity="0.33"
                stroke-width="0.519231"
              />
              <path
                d="M11.0771 18.6923L16.6156 13.1538L11.0771 7.61536"
                stroke="#013E9E"
                stroke-width="0.923077"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="mt-10 flex space-x-3 pb-10">
          <div v-for="i in accountsInfo.length" :key="i">
            <div
              :class="{ 'bg-primaryBlue': i == current + 1 }"
              class="w-3 h-3 bg-gray-200 rounded-full"
            ></div>
          </div>
        </div>
      </div>
    </trac-modal>
    <trac-modal v-if="newPaymentModal" @close="newPaymentModal = false">
      <div class="px-10">
        <h1 class=" text-center font-bold mt-16">Add Payment Method</h1>
        <trac-input
          v-model="customMethod"
          placeholder="Method Name"
          class="mt-20"
        >
        </trac-input>
        <div class="flex items-center justify-center">
          <trac-button
            @click.native="createCustomPaymentMethod"
            class="mt-20 mb-5"
          >
            <span class="px-6"> Create</span>
          </trac-button>
        </div>
      </div>
    </trac-modal>
    <trac-modal v-if="modal" @close="modal = false">
      <div class="px-10">
        <h1 class=" text-center font-bold mt-16">Add Bank Account</h1>
        <p class="text-sm text-center mt-5">
          You need to add your bank account information before a fixed account
          can be created
        </p>
        <div class="flex items-center justify-center">
          <trac-button
            @click.native="$router.push({ name: 'BankAccounts' })"
            class="mt-10 mb-5"
          >
            <span class="px-6"> Add Bank Account</span>
          </trac-button>
        </div>
      </div>
    </trac-modal>
  </div>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  GET_USER_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  name: "PaymentMethod",
  data() {
    return {
      modal: false,
      isLoading: false,
      paymentMethods: null,
      openModal: false,
      current: 0,
      checkBankDetails: false,
      fixedAccount: false,
      newPaymentModal: false,
      customMethod: "",
      customPayments: null,
      accountsInfo: [
        {
          icon: "pos.svg",
          name: "POS",
          text:
            "Collect payment on your POS payment terminal, POS transcations can also be recorded for cases where the app is not running on a terminal ",
        },
        {
          icon: "dynamic.svg",
          name: "Bank Transfer (Dynamic)",
          text:
            "Receive payment via bank transfer through a dynamic virtual account created for every transcation, thereby allowing your cashiers confirm payments without needing access to your account. Automatically reconciled to your sale (Settlement to your account is same day).",
        },
        {
          icon: "fixed.svg",
          name: "Bank Transfer (Fixed)",
          text:
            "Receive payments via bank transfer through a unique virtual account that is tied to your stores thereby helping cashiers of your various stores/outlets confirm payments instantly via the transcation module without needing access to your account. Automatically reconciled to your sale  (Settlement to your account is same day)",
        },
        {
          icon: "bank.svg",
          name: "BANK ACCOUNT",
          text: "Collect payment via direct transfers to your bank account",
        },
        {
          icon: "cash.svg",
          name: "CASH",
          text: "Physical collection of cash from customers.",
        },
        {
          icon: "online.svg",
          name: "ONLINE PAYMENT",
          text:
            "Receive payment via online payments and allow your cashiers confirm without needing access to your account through an online payment link. Automatically reconciled to your sale (Settlement to your account is same day)",
        },
        {
          icon: "online.svg",
          name: "USSD",
          text:
            "Receive payment via USSD transfer and allow your cashiers confirm without needing access to your account through a USSD code generated for your store. Automatically reconciled to your sale (Settlement to your account is same day)",
        },
      ],
    };
  },
  async created() {
    await this.$store.dispatch("FETCH_ALL_BANK_ACCOUNTS");
    const res = this.$store.getters["GET_ALL_BANK_ACCOUNTS"];
    if (res.data.length > 0) {
      this.checkBankDetails = true;
    }

    this.isLoading = true;
    await this.makeCustomPaymentRequest();
    this.fetchPaymentMethods();
    this.isLoading = false;
  },
  methods: {
    goToFixedAccount() {
      if (this.checkBankDetails) {
        this.$router.push({ name: "FixedAccount" });
      } else {
        this.modal = true;
      }
    },
    async createCustomPaymentMethod() {
      if (this.customMethod == "") {
        return;
      }
      let res = await this.$store.dispatch("ADD_NEW_CUSTOMER_PAYMENT_OPTION", {
        name: this.customMethod,
      });
      if (res.status) {
        await this.makeCustomPaymentRequest();
        this.newPaymentModal = false;
        this.customMethod = "";
      }
    },
    async makeCustomPaymentRequest() {
      let res = await this.$store.dispatch("CUSTOMER_PAYMENT_REQUEST");
      this.customPayments = res.data.items;
    },
    async removeCustomPayment(x) {
      let res = await this.$store.dispatch("DELETE_CUSTOM_PAYMENT", { id: x });
      await this.makeCustomPaymentRequest();
    },
    closeModal() {
      this.$store.commit("CHANGE_PAYMENT_MODAL");
      this.openModal = false;
    },
    back() {
      if (this.current > 0) {
        this.current -= 1;
      } else {
        return;
      }
    },
    front() {
      if (this.current >= 0 && this.current < this.accountsInfo.length - 1) {
        this.current += 1;
      } else {
        return;
      }
    },
    confirm() {
      this.$store.commit("CHANGE_PAYMENT_REFERENCE");
    },
    getImg(x) {
      return require(`@/assets/svg/paymentMethod/${x}`);
    },
    fetchPaymentMethods() {
      this.paymentMethods = GET_USER_DATA().payment_option;
      if (this.paymentMethods.includes("fixed_account")) {
        this.fixedAccount = true;
      }
    },
    togglePaymentMethod(e, option) {
      if (this.paymentMethods.includes("fixed_account") && this.fixedAccount) {
        this.fixedAccount = false;
      }
      if (this.paymentMethods.includes(option)) {
        this.paymentMethods = this.paymentMethods.filter(
          (method) => method !== option
        );
      } else {
        this.paymentMethods.push(option);
      }
    },
    async updatePaymentOptions() {
      if (this.paymentMethods.length === 0) {
        eventBus.$emit("trac-alert", {
          message: "At least one payment method must be enabled to continue.",
        });
        return;
      }
      this.isLoading = true;

      const payload = {
        cash: this.paymentMethods.includes("cash"),
        pos: this.paymentMethods.includes("pos"),
        own_bank_account: this.paymentMethods.includes("own_bank_account"),
        bank_transfer: this.paymentMethods.includes("bank_transfer"),
        online_payment: this.paymentMethods.includes("online_payment"),
        ussd: this.paymentMethods.includes("ussd"),
        fixed_account: this.paymentMethods.includes("fixed_account"),
      };

      await this.$store.dispatch("TOGGLE_PAYMENT_OPTIONS", payload);
      const res = this.$store.getters["GET_RESPONSE_MESSAGE"];

      if (res.status) {
        res.data.includes("fixed_account")
          ? (this.fixedAccount = true)
          : (this.fixedAccount = false);
        this.$store.commit("CHANGE_PAYMENT_REFERENCE");
        let userData = GET_LOCAL_DB_DATA("traction-app-user-data");

        userData.user.payment_option = res.data;

        SAVE_LOCAL_DB_DATA("traction-app-user-data", userData);

        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
